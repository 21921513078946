<template>
  <v-container>
    <v-row class="text-center" no-gutters>
      <v-col cols="12">
        <v-img alt="PARKING.COM" class="my-3" height="42" contain src="../assets/logo_huge_drk.png" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card class="mx-auto rounded-lg" max-width="374">
          <template slot="progress">
            <v-progress-linear color="red" height="10" indeterminate></v-progress-linear>
          </template>
          <div style="position: relative">
            <!-- <v-skeleton-loader
                    v-show="!zoneDetails.hasOwnProperty('image')"
                    height="260px"
                    class="rounded-t-lg"
                    type="image"
                ></v-skeleton-loader>-->
            <v-img class="rounded-t-lg" max-height="150px" style="filter: brightness(50%)" :src="zoneDetails.hasOwnProperty('image') &&
                zoneDetails.image != '' &&
                zoneDetails.image != null
                ? zoneDetails.image
                : zoneImgURL
              " :lazy-src="zoneDetails.hasOwnProperty('image') &&
      zoneDetails.image != '' &&
      zoneDetails.image != null
      ? zoneDetails.image
      : zoneImgURL
    " alt="Parking.com garage">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center" no-gutters>
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div class="booking_header white--text text-left mr-4">
              {{
                zoneDetails.hasOwnProperty("zoneName")
                ? zoneDetails.zoneName
                : ""
              }}
            </div>
          </div>
          <v-card-text>
            <v-container fluid pa-0>
              <v-row no-gutters class="mt-0">
                <v-col class="pa-1">
                  <p class="black--text">
                    Here's a estimate for your active session at
                    {{
                      zoneDetails.hasOwnProperty("zoneName")
                      ? zoneDetails.zoneName
                      : ""
                    }}
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-1 mx-0">
                <v-col cols="4" class="text-left pa-1">
                  <p class="booking_date_header mb-0">Start At:</p>
                </v-col>
                <v-col class="text-right pa-1">
                  <p class="booking_date mb-0">
                    {{
                      templateFlags === "ondemand-flag"
                      ? odDetails.hasOwnProperty("userDetail") &&
                        odDetails.userDetail.hasOwnProperty("entryTime")
                        ? formatToReceiptDateTime(
                          odDetails.userDetail.entryTime
                        )
                        : ""
                      : casinoDetails.hasOwnProperty("userDetail") &&
                        casinoDetails.userDetail.hasOwnProperty("entryTime")
                        ? formatToReceiptDateTime(
                          casinoDetails.userDetail.entryTime
                        )
                        : ""
                    }}
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters class="mx-0">
                <v-col cols="4" class="text-left pa-1">
                  <p class="booking_date_header">Stop At:</p>
                </v-col>
                <v-col class="text-right pa-1">
                  <p class="booking_date">
                    {{
                      templateFlags === "ondemand-flag"
                      ? odDetails.hasOwnProperty("userDetail") &&
                        odDetails.userDetail.hasOwnProperty("exitTime")
                        ? formatToReceiptDateTime(
                          odDetails.userDetail.exitTime
                        )
                        : ""
                      : casinoDetails.hasOwnProperty("userDetail") &&
                        casinoDetails.userDetail.hasOwnProperty("exitTime")
                        ? formatToReceiptDateTime(
                          casinoDetails.userDetail.exitTime
                        )
                        : ""
                    }}
                  </p>
                </v-col>
              </v-row>
              <v-container class="text-center">


                <v-row no-gutters>
                  <v-col cols="12" class=" text-center " style="font-size: 12px">
                    <v-row no-gutters class="pt-2 px-">
                      <v-col cols="4" class="text-left">
                        <p style="font-size: small" class="black--text">
                          Parking
                        </p>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-left">
                        <p style="font-size: small" class="black--text text-right">
                          ${{
                            templateFlags === "ondemand-flag"
                            ? odDetails.hasOwnProperty("userDetail") &&
                              odDetails.userDetail.hasOwnProperty("estimate") &&
                              odDetails.userDetail.estimate.hasOwnProperty("quotedRegularPrice")
                              ? odDetails.userDetail.estimate.quotedRegularPrice.toFixed(2)
                              : "-"
                            : casinoDetails.hasOwnProperty("userDetail") &&
                              casinoDetails.userDetail.hasOwnProperty("estimate") &&
                              casinoDetails.userDetail.estimate.hasOwnProperty(
                                "quotedRegularPrice"
                              )
                              ? casinoDetails.userDetail.estimate.quotedRegularPrice.toFixed(2)
                              : "-"
                          }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-0   ">
                      <v-col cols="4" class="text-left">
                        <v-row no-gutters>
                          <v-col cols="11">
                            <p style="font-size: small" class="black--text">
                              Service Fee
                            </p>
                          </v-col>
                          <v-col cols="1">
                            <v-tooltip bottom v-model="show">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon class="material-icons-outlined" dark x-small color="primary" v-bind="attrs"
                                  @click="show = !show" v-on="on">info</v-icon>
                              </template>
                              <span>This fee helps cover the use of the technology that provides a better parking
                                experience.</span>
                            </v-tooltip>
                          </v-col>

                        </v-row>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-left">
                        <p style="font-size: small" class="black--text text-right">
                          ${{
                            templateFlags === "ondemand-flag"
                            ? odDetails.hasOwnProperty("userDetail") &&
                              odDetails.userDetail.hasOwnProperty("estimate") &&
                              odDetails.userDetail.estimate.hasOwnProperty("totalFee")
                              ? odDetails.userDetail.estimate.totalFee.toFixed(2)
                              : "-"
                            : casinoDetails.hasOwnProperty("userDetail") &&
                              casinoDetails.userDetail.hasOwnProperty("estimate") &&
                              casinoDetails.userDetail.estimate.hasOwnProperty(
                                "totalFee"
                              )
                              ? casinoDetails.userDetail.estimate.totalFee.toFixed(2)
                              : "-"
                          }} </p>
                      </v-col>
                    </v-row>
                    <hr />
                    <v-row no-gutters class="mt-2">
                      <v-col cols="6" class="text-left">
                        <p style="font-weight: bold;color:#F2555C;font-size:15px">
                          Purchase Total <sup>*</sup>
                        </p>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-left">
                        <p style="font-size: small;font-weight: bold;color:#F2555C" class=" text-right">
                          ${{
                            templateFlags === "ondemand-flag"
                            ? odDetails.hasOwnProperty("userDetail") &&
                              odDetails.userDetail.hasOwnProperty("estimate") &&
                              odDetails.userDetail.estimate.hasOwnProperty(
                                "grandTotal"
                              )
                              ? odDetails.userDetail.estimate.grandTotal.toFixed(2)
                              : "-"
                            : casinoDetails.hasOwnProperty("userDetail") &&
                              casinoDetails.userDetail.hasOwnProperty("estimate") &&
                              casinoDetails.userDetail.estimate.hasOwnProperty(
                                "grandTotal"
                              )
                              ? casinoDetails.userDetail.estimate.grandTotal.toFixed(2)
                              : "-"
                          }}

                        </p>
                      </v-col>
                      <p>
                        * Includes local and state taxes of ${{
                          templateFlags === "ondemand-flag"
                          ? odDetails.hasOwnProperty("userDetail") &&
                            odDetails.userDetail.hasOwnProperty("estimate") &&
                            odDetails.userDetail.estimate.hasOwnProperty("totalTax")
                            ? odDetails.userDetail.estimate.totalTax.toFixed(2)
                            : "-"
                          : casinoDetails.hasOwnProperty("userDetail") &&
                            casinoDetails.userDetail.hasOwnProperty("estimate") &&
                            casinoDetails.userDetail.estimate.hasOwnProperty(
                              "totalTax"
                            )
                            ? casinoDetails.userDetail.estimate.totalTax.toFixed(2)
                            : "-"
                        }}
                      </p>
                    </v-row>
                    <v-row no-gutters class="px-3">
                      <v-col class="text-left">

                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-container>
          </v-card-text>
          <v-card-actions class="text-center pt-0 mt-0 pb-10">
            <v-container class="pa-0" fluid>
              <!-- <v-row>
                                                <v-col cols="12">
                                                  <a class="text-caption" @click="navigateToReceipt()">View Receipt</a>
                                                </v-col>
                                              </v-row> -->
              <v-row no-gutters>
                <v-col cols="12" class="px-2">
                  <v-btn v-if="templateFlags === 'ondemand-flag'" rounded elevation="20" :loading="odSessionLoading"
                    @click="createODSession" class="add_licence_plate_btn white--text">Continue</v-btn>
                </v-col>
                <v-col>
                  <v-btn v-if="createAcc" rounded elevation="20" class="add_licence_plate_btn white--text">Create
                    Account</v-btn>
                </v-col>
                <v-col>
                  <v-btn v-if="templateFlags === 'atlantic-flag'" @click="casinoSession" :loading="odSessionLoading"
                    rounded elevation="20" class="add_licence_plate_btn white--text">Continue</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="alertDialog" persistent max-width="290">
      <v-card class="pa-0">
        <v-card-title class="justify-center" style="position: relative">
          <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="text-center mt-5">
          <span style="font-size: 20px">{{ alertMsg }}</span>
        </v-card-text>
        <v-card-actions class="text-center pb-6">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="0" class="white--text exit_dialog_btn"
                  @click="alertDialog = false">Close</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import APIHelper from "../apiHelper";
import rules from "@/utils/rules";
import { format } from "date-fns";
export default {
  data: (vm) => ({
    show: false,
    alertDialog: false,
    alertMsg: "",
    odSessionLoading: false,
    time: null,
    createAcc: false,
    Timemenu: false,
    Datemenu: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateFormatted: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    rules: rules,
    licencePlate: "",
  }),
  components: {},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let searchParams = window.location.search.split("=");
      let searchParamKey = searchParams.length > 0 ? searchParams[0] : "";
      let searchParamValue = searchParams.length > 0 ? searchParams[1] : "";
      // To check the route if the flow is ondemand or atlantic city flow
      if (window.location.href.includes("ondemand")) {
        vm.$store.commit("SET_TEMPLATE_FLAGS", "ondemand-flag");
      }
      if (window.location.href.includes("prepaidhost")) {
        vm.$store.commit("SET_TEMPLATE_FLAGS", "atlantic-flag");
      }
      switch (searchParamKey) {
        case "?zcode":
          vm.getZoneDetails(searchParamKey, searchParamValue);
          break;
        case "?gateid":
          //await self.getZoneDetails(searchParamKey, searchParamValue);
          break;
      }
    });
  },
  methods: {
    formatToReceiptDateTime(dateTime) {
      return dateTime != null && dateTime != undefined && dateTime != ""
        ? format(
          new Date(dateTime.replaceAll("-", "/")),
          "MMM dd, yyyy hh:mm a"
        )
        : "";
    },
    navigateToReceipt() {
      this.$router.push({ path: "/receipt" });
    },

    // On demand create session
    async createODSession() {
      try {
        this.odSessionLoading = true;
        let exitDateTime = format(
          new Date(this.odDetails?.userDetail?.exitTime.replaceAll("-", "/")),
          "yyyy-MM-dd HH:mm:ss"
        );
        let entryDateTime = format(
          new Date(this.odDetails?.userDetail?.entryTime.replaceAll("-", "/")),
          "yyyy-MM-dd HH:mm:ss"
        );
        let postData = {
          userDetail: {
            plate: this.odDetails?.userDetail?.plate,
            plateRegion:{
              country:this.odDetails?.userDetail?.plateRegion?.country,
              state:this.odDetails?.userDetail?.plateRegion?.state,
            },
            contact: this.odDetails?.userDetail?.contact,
            exitTime: exitDateTime,
            eventType: "OD",
          },
          cardDetail: {
            cardType: this.odDetails?.cardDetail?.cardType,
            userDevice: "2",
            orderType: "3",
            entry: [
              {
                locationCode: this.zoneDetails?.zcode,
                startAt: entryDateTime,
                quantity: "1",
              },
            ],
            payment: {
              lastFourDigits: this.odDetails?.payment?.lastFourDigits,
              expirationMonth: this.odDetails?.payment?.expirationMonth,
              expirationYear: this.odDetails?.payment?.expirationYear,
              postalCode: this.odDetails?.payment?.postalCode,
              authorizationToken: this.odDetails?.payment?.authorizationToken,
              email: this.odDetails?.payment?.email,
              saveCard: this.odDetails?.payment?.saveCard,
              isDefault: this.odDetails?.payment?.isDefault,
              preAuthorizedOnly: this.odDetails?.payment?.preAuthorizedOnly,
            },
          },
          mid: this.zoneDetails.mid,
          source: "web"
        };
        var odAddCard = await APIHelper(
          "POST",
          "/api/v1/prepaid/addCard",
          postData
        );
        if (odAddCard?.data?.status == true) {
          //url segment is app mixin computed property
          let url = window.location.origin + '/g/' + odAddCard?.data?.data?.bid;
          window.location.replace(url);
        } else if (odAddCard?.data?.status == false) {
          this.alertDialog = true;
          this.alertMsg = odAddCard?.data?.message;
        }
        this.odSessionLoading = false;
        // this.$router.replace({ path: 'odExtension' })
      } catch (error) {
        this.odSessionLoading = false;
        console.log(error);
      }
    },

    //Atlantic flow create session

    async casinoSession() {
      try {
        this.odSessionLoading = true;
        let exitDateTime = format(
          new Date(
            this.casinoDetails?.userDetail?.exitTime.replaceAll("-", "/")
          ),
          "yyyy-MM-dd HH:mm:ss"
        );
        let entryDateTime = format(
          new Date(
            this.casinoDetails?.userDetail?.entryTime.replaceAll("-", "/")
          ),
          "yyyy-MM-dd HH:mm:ss"
        );
        let postData = {
          mid: this.zoneDetails.mid,
          userDetail: {
            plate: this.casinoDetails?.userDetail?.plate,
            plateRegion:{
              country:this.casinoDetails?.userDetail?.plateRegion?.country,
              state:this.casinoDetails?.userDetail?.plateRegion?.state,
            },
            contact: this.casinoDetails?.userDetail?.contact,
            exitTime: exitDateTime,
            eventType: "PaidHostPass",
          },

          cardDetail: {
            cardType: this.casinoDetails?.cardDetail?.cardType,
            userDevice: "2",
            orderType: "3",
            entry: [
              {
                locationCode: this.zoneDetails?.zcode,
                startAt: entryDateTime,
                quantity: "1",
              },
            ],
            payment: {
              lastFourDigits: this.casinoDetails?.payment?.lastFourDigits,
              expirationMonth: this.casinoDetails?.payment?.expirationMonth,
              expirationYear: this.casinoDetails?.payment?.expirationYear,
              postalCode: this.casinoDetails?.payment?.postalCode,
              authorizationToken:
                this.casinoDetails?.payment?.authorizationToken,
              email: this.casinoDetails?.payment?.email,
              saveCard: this.casinoDetails?.payment?.saveCard,
              isDefault: this.casinoDetails?.payment?.isDefault,
              preAuthorizedOnly: this.casinoDetails?.payment?.preAuthorizedOnly,
            },

          },
          bid: this.casinoDetails?.userDetail?.bid,
        };
        var addCard = await APIHelper(
          "POST",
          "/api/v1/prepaid/addCard",
          postData
        );
        if (addCard?.data?.status == true) {
          //url segment is app mixin computed property
          let url = window.location.origin + '/g/' + addCard?.data?.data?.bid;
          window.location.replace(url);
        } else if (addCard?.data?.status == false) {
          this.alertDialog = true;
          this.alertMsg = addCard?.data?.message;
        }
        this.odSessionLoading = false;
        // this.$router.replace({ path: 'odExtension' })
      } catch (error) {
        this.odSessionLoading = false;
        console.log(error);
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    uppercase() {
      if (rules.alphaNumeric(this.licencePlate) === "Must be alphanumeric") {
        this.licencePlate = this.licencePlate.replace(/[^\w\s]/gi, '')
      }
      this.licencePlate = this.licencePlate.trim()
      this.licencePlate = this.licencePlate.toUpperCase();
    },
    /**
     * @method getZoneDetails fetch the zone details and commit SET_ZONE_DETAILS mutation.
     */
    async getZoneDetails(searchParamKey, searchParamValue) {
      try {
        var zoneDetails = await APIHelper(
          "GET",
          "/api/v1/user/config" + searchParamKey + "=" + searchParamValue
        );
        this.$store.commit("SET_ZONE_DETAILS", zoneDetails.data?.zone);
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },

  computed: {
    ...mapGetters({
      zoneDetails: "getterZoneDetails",
      odDetails: "getterOdDetails",
      casinoDetails: "getterCasinoDetails",
      templateFlags: "getterTemplateFlags",
    }),
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    disableSubmitBtn() {
      let disabled =
        this.contactObj == null ||
        !this.contactObj.isValid ||
        this.contactObj.phoneNumber == "" ||
        this.contactObj.phoneNumber == null ||
        this.time == null ||
        this.time === "" ||
        this.date === null ||
        this.date === "";
      disabled =
        this.licencePlate != "" && this.licencePlate != null
          ? disabled || /^[a-z0-9]+$/i.test(this.licencePlate) === false
          : disabled;
      return disabled;
    },
  },
};
</script>
<style scoped>
.booking_date {
  font-weight: normal;
  font-size: 13px;
  color: #3d3f3c !important;
}

.booking_date_header {
  color: #3d3f3c !important;
  font-weight: bold;
  font-size: 14px;
}

hr {
  border: 1px solid #ffbc1f;
}

.add_licence_plate_btn {
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
  height: 50px !important;
}
</style>